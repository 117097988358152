<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ usreList.length }}) My Deliveries</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="usreList"
      item-key="full_name"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.full_name`]="{item}">
        <div class="d-flex flex-column" @click="getMoreInfo(item.id)" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
          <small>{{ item.location }}</small>
        </div>
      </template>
      <template #[`item.amount`]="{item}">
        {{ item.amount }}
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="statusColor[item.status]"
          class="font-weight-medium"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template #[`item.date_time`]="{item}">
        {{ item.date_time }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'

export default {
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Pending: 'primary',
      Delivered: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    const data = [
        {
      responsive_id: '',
        id: 95,
        full_name: 'Edwina Ebsworth',
        location: 'EMIA Yaounde',
        amount: '10,000 XAF',
        status: 'Pending',
        validation: false,
        date_time: 'Wednesday, 5 Jan. 2022 - 11:13 PM',
      },
      {
        responsive_id: '',
        id: 1,
        full_name: "Korrie O'Crevy",
        location: 'UBA Avenue Des Banques',
        amount: '10,000,000 XAF',
        status: 'Cancelled',
        validation: false,
        date_time: 'Wednesday, 5 Jan. 2021 - 11:03 AM',
      },
      {
        responsive_id: '',
        id: 7,
        full_name: "Falloon O'De",
        location: 'Total Melen Yaounde',
        amount: '500,000 XAF',
        status: 'Delivered',
        validation: true,
        date_time: 'Wednesday, 5 Jan. 2021 - 10:13 AM',
      },
      {
        responsive_id: '',
        id: 3,
        full_name: 'Stella Ganderton',
        location: 'Nso Boyz Yaounde',
        amount: '35,000 XAF',
        status: 'Delivered',
        validation: true,
        date_time: 'Wednesday, 5 Jan. 2021 - 11:00 AM',
      },
      {
        responsive_id: '',
        id: 4,
        full_name: 'Dorolice Crossman',
        location: 'Polytechnique Yaounde',
        amount: '17,000 XAF',
        status: 'Delivered',
        validation: true,
        date_time: 'Friday, 5 Jan. 2021 - 09:13 PM',
      },
    ]

    return {
      switch1: true,
      headers: [
        { text: 'Name', value: 'full_name' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      usreList: data,
      statusColor,
      search: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  methods:{
    getMoreInfo(info_id){
      this.$store.commit('setDialogTitle', "Getting More Information for "+info_id)
      this.$store.commit('setDialogMessage', "Your information has been collected. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months. <ul><li>This is one</li><li>This is two</li><li>This is three</li><li>This is four</li></ul>")
      this.$store.commit('activateDialog', true)
    }
  },
}
</script>
